<template>
    <settingsPagesLayout>
        <section class="wrap_s_block">
            <h2 class="name_section">Current Plan</h2>
            <div class="body_ membership">
                <div class="box_current_plan free">
                    <div class="current_plan">
                        <div class="name">
                            Free plan
                            <span>No credit card required</span>
                        </div>
                    </div>
                    <button type="button" v-ripple class="change_plan tetriatary_btn small" @click="changePlan">Change plan</button>
                    <button type="button" v-ripple class="level_up c2a_btn small" @click="changePlan">
                        Level Up
                        <span class="arrow">
                            <span class="line_svg"></span>
                            <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
                                <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
                            </svg>
                        </span>
                    </button>
                </div>
                <div class="box_payment_method">
                    <h2 class="name_section">Payment Method</h2>
                    <div class="credit_cards">
                        <div class="item_card">

                        </div>
                        <div class="add_credit_card" v-ripple>
                            <span class="_icon" >
                                <span class="material-icons-outlined">add_circle</span>
                            </span>
                            Add Credit Card
                        </div>
                    </div>

                </div>

            </div>
        </section>
        <div style="height: 120px;"></div>
        <comingSoonDialog v-if="dialogComingSoon" v-model="dialogComingSoon"></comingSoonDialog>
    </settingsPagesLayout>
</template>

<script>
import settingsPagesLayout from "@/layouts/settingsPagesLayout";
import comingSoonDialog from '@/components/dialogs/comingSoonDialog.vue';

export default {
    name: 'membership',
    components: {
        settingsPagesLayout,
        comingSoonDialog
    },
    data() {
        return {
            dialogComingSoon: false
        }
    },
    methods: {
        changePlan() {
            this.dialogComingSoon = true;
        }
    }
}
</script>

<style scoped>

</style>
